














































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FooterTranslations } from '../../generic/translations/Footer.translations'
import { CultureService } from '../../services/CultureService'
import { Culture } from '../../generic/models/Culture'

@Component
export default class YoutubeVideoComponent extends Vue {
  @Prop() videoId!: string;
  @Prop() private translations !: FooterTranslations

  private get youtubeVideoUrl (): string {
    return 'https://www.youtube.com/embed/' + this.videoId
  }

  private get youtubeVideoThumbnailUrl (): string {
    return `url("https://i3.ytimg.com/vi/${this.videoId}/maxresdefault.jpg")`
  }

  private get labelCookiePreferences () : string {
    if (CultureService.getCulture() === Culture.French) {
      return 'Préférences témoins'
    }
    return 'Cookie preferences'
  }

  private get labelWatchOnYouTube () : string {
    if (CultureService.getCulture() === Culture.French) {
      return 'Regarder sur YouTube'
    }
    return 'Watch on YouTube'
  }

  private openCookiesPopup (): void {
    // Open cookies preferences popup, using the function in Axeptio's script.
    openAxeptioCookies()
  }
}
